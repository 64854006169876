import { ReactElement, ReactNode } from "react";
import styled, { css } from "styled-components";
import theme, { Spacing } from "../Global/theme";

interface Props {
  children: ReactNode | ReactNode[];
  spacingTop?: Spacing;
  spacingBottom?: Spacing;
  spacingLeft?: Spacing;
  spacingRight?: Spacing;
}

interface StyledDivProps {
  $spacingTop?: Spacing;
  $spacingBottom?: Spacing;
  $spacingLeft?: Spacing;
  $spacingRight?: Spacing;
}

const StyledDiv = styled.div<StyledDivProps>(
  ({ $spacingTop, $spacingBottom, $spacingLeft, $spacingRight }) => {
    return css`
      ${$spacingTop
        ? css`
            ${typeof $spacingTop === "object" &&
            css`
              margin-top: ${$spacingTop.small}px;

              @media screen and (min-width: ${theme.breakpoints.medium}) {
                margin-top: ${$spacingTop.medium}px;
              }
              @media screen and (min-width: ${theme.breakpoints.large}) {
                margin-top: ${$spacingTop.large}px;
              }
            `}

            ${typeof $spacingTop === "number" &&
            css`
              margin-top: ${$spacingTop}px;
            `}
          `
        : null}

      ${$spacingBottom
        ? css`
            ${typeof $spacingBottom === "object" &&
            css`
              margin-bottom: ${$spacingBottom.small}px;

              @media screen and (min-width: ${theme.breakpoints.medium}) {
                margin-bottom: ${$spacingBottom.medium}px;
              }
              @media screen and (min-width: ${theme.breakpoints.large}) {
                margin-bottom: ${$spacingBottom.large}px;
              }
            `}

            ${typeof $spacingBottom === "number" &&
            css`
              margin-bottom: ${$spacingBottom}px;
            `}
          `
        : null}

      ${$spacingLeft
        ? css`
            ${typeof $spacingLeft === "object" &&
            css`
              margin-left: ${$spacingLeft.small}px;
              @media screen and (min-width: ${theme.breakpoints.medium}) {
                margin-left: ${$spacingLeft.medium}px;
              }
              @media screen and (min-width: ${theme.breakpoints.large}) {
                margin-left: ${$spacingLeft.large}px;
              }
            `}

            ${typeof $spacingLeft === "number" &&
            css`
              margin-left: ${$spacingLeft}px;
            `}
          `
        : null}

      ${$spacingRight
        ? css`
            ${typeof $spacingRight === "object" &&
            css`
              margin-right: ${$spacingRight.small}px;
              @media screen and (min-width: ${theme.breakpoints.medium}) {
                margin-right: ${$spacingRight.medium}px;
              }
              @media screen and (min-width: ${theme.breakpoints.large}) {
                margin-right: ${$spacingRight.large}px;
              }
            `}

            ${typeof $spacingRight === "number" &&
            css`
              margin-right: ${$spacingRight}px;
            `}
          `
        : null}
    `;
  }
);

function PushOrg({
  children,
  spacingTop,
  spacingBottom,
  spacingLeft,
  spacingRight,
}: Props): ReactElement<Props> {
  return (
    <StyledDiv
      $spacingTop={spacingTop}
      $spacingBottom={spacingBottom}
      $spacingLeft={spacingLeft}
      $spacingRight={spacingRight}
    >
      {children}
    </StyledDiv>
  );
}

export default PushOrg;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import ScrollToTop from "./helpers/ScrollToTop";
import Loader from "./components/LoaderAtm";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <React.Suspense fallback={<Loader height="100vh" />}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </React.Suspense>
  </React.StrictMode>
);

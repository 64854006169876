import { ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "../Global/theme";

export interface ExternalLinkAtmProps {
  to?: string;
  href?: string;
  openInNewTab?: boolean;
  children: ReactNode;
}

const style = `cursor: pointer;
text-decoration: none;
color: inherit;
font-size: inherit;
font-family: inherit;
font-weight: inherit;
transition: 0.3s ease-in-out;

&:hover {
  color: ${theme.colors.secondary};
}`;

const ExternalLink = styled.a`
  ${style}
`;

const InternalLink = styled(Link)`
  ${style}
`;

function ExternalLinkAtm({
  to,
  href,
  openInNewTab,
  children,
}: ExternalLinkAtmProps): ReactElement<ExternalLinkAtmProps> | null {
  let LinkJSX;

  if (href) {
    LinkJSX = (
      <ExternalLink href={href} target={openInNewTab ? "_blank" : "_self"}>
        {children}
      </ExternalLink>
    );
  }

  if (to) {
    LinkJSX = <InternalLink to={to}>{children} </InternalLink>;
  }

  return LinkJSX ? LinkJSX : null;
}

export default ExternalLinkAtm;

import { ReactElement, ReactNode } from "react";
import styled, { css } from "styled-components";
import theme, { Spacing } from "../Global/theme";

interface Props {
  children: ReactNode;
  width: Spacing;
}

interface StyledWidthMolProps {
  $width: Spacing;
}

const StyledWidthMolMol = styled.div<StyledWidthMolProps>(({ $width }) => {
  return css`
    ${$width
      ? css`
          ${typeof $width === "object" &&
          css`
            max-width: ${$width.small}px;

            @media screen and (min-width: ${theme.breakpoints.medium}) {
              max-width: ${$width.medium}px;
            }
            @media screen and (min-width: ${theme.breakpoints.large}) {
              max-width: ${$width.large}px;
            }
          `}

          ${typeof $width === "number" &&
          css`
            max-width: ${$width}px;
          `}
        `
      : null}
  `;
});

function WidthMol({ children, width }: Props): ReactElement<Props> {
  return <StyledWidthMolMol $width={width}>{children}</StyledWidthMolMol>;
}

export default WidthMol;

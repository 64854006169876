import { ReactElement } from "react";
import styled, { css } from "styled-components";
import FlexOrg from "../FlexOrg";

interface WrapperProps {
  $height?: string;
}

interface Props {
  height?: string;
}
const Wrapper = styled.div<WrapperProps>(({ $height }) => {
  return css`
    ${$height
      ? css`
          height: ${$height};
        `
      : null}
  `;
});

function Loader({ height }: Props): ReactElement<Props> {
  return (
    <Wrapper $height={height}>
      <FlexOrg alignItems="center" justify="center" hassFullHeight>
        <img src="images/icons/loader.gif" alt="infinity" />
      </FlexOrg>
    </Wrapper>
  );
}

export default Loader;

import { ReactElement, ReactNode } from "react";
import styled, { css } from "styled-components";
import theme, { Spacing } from "../Global/theme";

interface Props {
  children: ReactNode | ReactNode[];
  columns: Spacing;
  gap?: Spacing;
  columReverseOnMobile?: boolean;
}

interface StyledContainer {
  $columns: Spacing;
  $gap: Spacing;
  $columReverseOnMobile?: boolean;
}

const StyledDiv = styled.div<StyledContainer>(
  ({ $columns, $gap, $columReverseOnMobile }) => {
    return css`
      display: grid;

      ${$columReverseOnMobile &&
      css`
        @media screen and (max-width: ${theme.breakpoints.medium}) {
          & > *:nth-last-child(1) {
            order: -1;
          }
        }
      `}

      ${typeof $columns === "object" &&
      css`
        grid-template-columns: repeat(${$columns.small}, 1fr);

        @media screen and (min-width: ${theme.breakpoints.medium}) {
          grid-template-columns: repeat(${$columns.medium}, 1fr);
        }
        @media screen and (min-width: ${theme.breakpoints.large}) {
          grid-template-columns: repeat(${$columns.large}, 1fr);
        }
      `}

      ${typeof $columns === "number" &&
      css`
        grid-template-columns: repeat(${$columns}, 1fr);
      `}

    ${typeof $gap === "object" &&
      css`
        gap: ${$gap.small}px;

        @media screen and (min-width: ${theme.breakpoints.medium}) {
          gap: ${$gap.medium}px;
        }
        @media screen and (min-width: ${theme.breakpoints.large}) {
          gap: ${$gap.large}px;
        }
      `}

    ${typeof $gap === "number" &&
      css`
        gap: ${$gap}px;
      `}
    `;
  }
);

function GridOrg({
  children,
  columns,
  gap = 16,
  columReverseOnMobile,
}: Props): ReactElement<Props> {
  return (
    <StyledDiv
      $columns={columns}
      $gap={gap}
      $columReverseOnMobile={columReverseOnMobile}
    >
      {children}
    </StyledDiv>
  );
}

export default GridOrg;

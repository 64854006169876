import { Route, Routes } from "react-router-dom";
import { lazy } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import GlobalStyle from "./globalStyles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Error = lazy(() => import("./pages/Error"));
const Team = lazy(() => import("./pages/Team"));
const School = lazy(() => import("./pages/School"));
const Horses = lazy(() => import("./pages/Horses"));
const Offer = lazy(() => import("./pages/Offer"));
const Contact = lazy(() => import("./pages/Contact"));
const Privacy = lazy(() => import("./pages/Privacy"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />

      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/horses" element={<Horses />} />
        <Route path="/school" element={<School />} />
        <Route path="/offer" element={<Offer />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<Error />} />
      </Routes>

      <Footer />
    </QueryClientProvider>
  );
}
export default App;

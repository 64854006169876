import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import theme from "../../Global/theme";

interface LanguageType {
  pl: {
    nativename: string;
  };
  en: {
    nativename: string;
  };
}

const lngs: LanguageType = {
  pl: {
    nativename: "PL",
  },
  en: {
    nativename: "EN",
  },
};

const Select = styled.select`
  padding: 0;
  border: none;
  font-size: inherit;
  font-family: inherit;
  background-color: ${theme.colors.third};

  @media screen and (max-width: ${theme.breakpoints.medium}) {
    margin-right: 16px;
  }

  :focus-visible {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

function LanguageButton() {
  const { i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState("pl");

  function handleClik() {
    if (selectedOption === "pl") {
      setSelectedOption("en");
    }
    if (selectedOption === "en") {
      setSelectedOption("pl");
    }
  }

  useEffect(() => {
    i18n.changeLanguage(selectedOption);
  }, [selectedOption, i18n]);

  return (
    <Select onChange={handleClik}>
      {Object.keys(lngs).map((lng) => (
        <option key={lng}>{lngs[lng as keyof LanguageType].nativename}</option>
      ))}
    </Select>
  );
}
export default LanguageButton;

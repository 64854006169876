import { ReactElement, ReactNode } from "react";
import styled, { css } from "styled-components";
import theme from "../Global/theme";

type AlignItems = "center" | "flex-start" | "flex-end";

type Justify =
  | "center"
  | "flex-start"
  | "flex-end"
  | "space-between"
  | "space-around";

type AlignContent =
  | "center"
  | "flex-start"
  | "flex-end"
  | "space-between"
  | "space-around";

type FlexDirection = "column" | "row";

interface Props {
  children: ReactNode | ReactNode[];
  alignItems?: AlignItems;
  justify?: Justify;
  alignContent?: AlignContent;
  columReverseOnMobile?: boolean;
  flexDirection?: FlexDirection;
  flexWrap?: boolean;
  hassFullHeight?: boolean;
}

interface StyledContainer {
  $alignItems?: AlignItems;
  $justify?: Justify;
  $alignContent?: AlignContent;
  $columReverseOnMobile?: boolean;
  $flexDirection?: FlexDirection;
  $flexWrap?: boolean;
  $hassFullHeight?: boolean;
}

const StyledDiv = styled.div<StyledContainer>(
  ({
    $alignItems,
    $justify,
    $alignContent,
    $columReverseOnMobile,
    $flexDirection,
    $flexWrap,
    $hassFullHeight,
  }) => {
    return css`
      display: flex;
      position: relative;

      ${$hassFullHeight &&
      css`
        height: 100%;
      `}

      ${$columReverseOnMobile
        ? css`
            flex-direction: ${$flexDirection === "row"
              ? "row-reverse"
              : "column-reverse"};
          `
        : css`
            flex-direction: ${$flexDirection ? $flexDirection : "column"};
          `}

      ${$flexWrap &&
      css`
        flex-wrap: wrap;
      `} 
      
      ${$alignContent &&
      css`
        align-content: ${$alignContent};
      `}

      ${$justify &&
      css`
        justify-content: ${$justify};
      `}

      ${$alignItems &&
      css`
        align-items: ${$alignItems};
      `}

      @media screen and (min-width: ${theme.breakpoints.medium}) {
        flex-direction: ${$flexDirection ? $flexDirection : "column"};
      }
    `;
  }
);

function FlexOrg({
  children,
  alignItems,
  justify,
  alignContent,
  columReverseOnMobile,
  flexDirection,
  flexWrap,
  hassFullHeight,
}: Props): ReactElement<Props> {
  return (
    <StyledDiv
      $alignItems={alignItems}
      $justify={justify}
      $alignContent={alignContent}
      $columReverseOnMobile={columReverseOnMobile}
      $flexDirection={flexDirection}
      $flexWrap={flexWrap}
      $hassFullHeight={hassFullHeight}
    >
      {children}
    </StyledDiv>
  );
}

export default FlexOrg;

const theme = {
  colors: {
    primary: "#003153",
    secondary: "#CBA956",
    third: "#FAF6FF",
    fourth: "#FFFFFF",
    fifth: "#E6A340",
    background: "#FAF7FF",
  },
  typography: {
    giant: {
      fontFamily: "Lora",
      fontWeight: 700,
      small: {
        lineHeight: "40px",
        fontSize: "110px",
      },
      large: {
        lineHeight: "40px",
        fontSize: "110px",
      },
    },
    largeBold: {
      fontFamily: "Lora",
      fontWeight: 700,
      small: {
        fontSize: "32px",
        lineHeight: "125%",
      },
      large: {
        fontSize: "40px",
        lineHeight: "125%",
      },
    },
    large: {
      fontFamily: "Lora",
      fontWeight: 400,
      small: {
        fontSize: "23px",
        lineHeight: "35px",
      },
      large: {
        fontSize: "40px",
        lineHeight: "55px",
      },
    },
    medium: {
      fontFamily: "Lora",
      fontWeight: 700,
      small: {
        fontSize: "20px",
        lineHeight: "30px",
      },
      large: {
        fontSize: "25px",
        lineHeight: "35px",
      },
    },
    mediumS: {
      fontFamily: "Lora",
      fontWeight: 400,
      small: {
        fontSize: "15px",
        lineHeight: "25px",
      },
      large: {
        fontSize: "20px",
        lineHeight: "30px",
      },
    },
    small: {
      fontFamily: "Roboto",
      fontWeight: 400,
      small: {
        fontSize: "14px",
        lineHeight: "21px",
      },
      large: {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  },
  width: {
    large: "100%",
    default: "1200px",
    medium: "1024px",
    narrow: "780px",
  },
  breakpoints: {
    small: "768px",
    medium: "1024px",
    large: "1280px",
    huge: "1440px",
  },
} as const;

export default theme;

export type Colors = keyof typeof theme.colors;
export type Typography = keyof typeof theme.typography;
export type Spacing = number | { large: number; medium: number; small: number };

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Lora';
  src: url('/fonts/Lora-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Lora';
  src: url('/fonts/Lora-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap
}

 * {
  box-sizing: border-box;
  margin: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  background-color: #FAF7FF;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  height: auto !important;
}

ul, ol {
  list-style-position: inside;
}

ul {
  list-style-type: disc;
}
`;

export default GlobalStyle;

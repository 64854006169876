import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import theme from "../Global/theme";
import Hamburger from "./Hamburger";
import LanguageButton from "./LanguageSelect";

const menuHeight = "100px";

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  background-color: ${theme.colors.background};
  height: ${menuHeight};
  z-index: 100;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
`;

const NavContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${theme.breakpoints.large};
  width: 100%;
  padding: 1rem 1rem;
`;

interface BurgerBarProps {
  $isHamburgerOpen: boolean;
}

const LinksWrapper = styled.ul<BurgerBarProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: ${theme.colors.third};
  transition: all 0.3s ease-in-out;
  transform: translateX(${(props) => (props.$isHamburgerOpen ? 0 : "-100%")});
  opacity: ${(props) => (props.$isHamburgerOpen ? "1" : "0")};

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: ${menuHeight};
    padding: 0;
    opacity: 1;
    transform: translateX(0);
  }
`;

const LinkWrapper = styled.li`
  margin: 10px 0;
  list-style-type: none;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    margin: 0 20px;
  }
`;

interface MenuLintProps {
  $isActive?: boolean;
}

const MenuLink = styled(Link)<MenuLintProps>(({ $isActive }) => {
  return css`
    text-decoration: none;
    color: ${theme.colors.primary};
    text-transform: uppercase;

    ${$isActive &&
    css`
      color: ${theme.colors.secondary};
    `}

    @media screen and (min-width: ${theme.breakpoints.medium}) {
      position: relative;
      display: inline-block;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -10px;
        left: 0;
        background-color: ${theme.colors.secondary};
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s;
      }
      &:hover:before {
        visibility: visible;
        transform: scaleX(1);
      }
    }
  `;
});

const Logo = styled(Link)`
  text-decoration: none;
  width: 85px;
  height: 65px;
  z-index: 10;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  z-index: 10;
`;

function Navbar() {
  const [isHamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!isHamburgerOpen);
  };

  useEffect(() => {
    if (isHamburgerOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.removeAttribute("style");
    }
  }, [isHamburgerOpen]);

  const { t } = useTranslation();

  const links = [
    {
      to: "/",
      text: t("menu.home"),
    },
    {
      to: "/about",
      text: t("menu.about"),
    },
    {
      to: "/horses",
      text: t("menu.horses"),
    },
    {
      to: "/school",
      text: t("menu.school"),
    },
    {
      to: "/offer",
      text: t("menu.offer"),
    },
    {
      to: "/team",
      text: t("menu.team"),
    },
    {
      to: "/contact",
      text: t("menu.contact"),
    },
  ];

  const location = useLocation();

  const menuLinks = links.map((link) => (
    <LinkWrapper key={link.text}>
      <MenuLink
        to={link.to}
        onClick={isHamburgerOpen ? toggleHamburger : undefined}
        $isActive={location.pathname === link.to}
      >
        {link.text}
      </MenuLink>
    </LinkWrapper>
  ));

  return (
    <Nav>
      <NavContainer>
        <Logo to="/" onClick={() => setHamburgerOpen(false)}>
          <img
            src="/images/icons/logo-menu.svg"
            alt="logo"
            height={65}
            width={86}
            loading="eager"
          />
        </Logo>

        <LinksWrapper $isHamburgerOpen={isHamburgerOpen}>
          {menuLinks}
        </LinksWrapper>

        <ButtonsWrapper>
          <LanguageButton />

          <Hamburger
            isHamburgerOpen={isHamburgerOpen}
            onClick={toggleHamburger}
          />
        </ButtonsWrapper>
      </NavContainer>
    </Nav>
  );
}

export default Navbar;

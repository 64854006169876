import { ReactElement, ReactNode } from "react";
import styled, { css } from "styled-components";
import theme, { Colors, Spacing } from "../Global/theme";

type HTMLTag = "footer" | "section";

interface Props {
  children: ReactNode | ReactNode[];
  backgroundImage?: string;
  backgroundSmallImage?: string;
  backgroundColor?: Colors;
  spacingTop?: Spacing;
  spacingBottom?: Spacing;
  htmlTag?: HTMLTag;
  margin?: Spacing;
  hasDisplayBlockOnMedium?: boolean;
}

interface StyledSectionProps {
  $backgroundImage?: string;
  $backgroundSmallImage?: string;
  $backgroundColor?: Colors;
  $spacingTop?: Spacing;
  $spacingBottom?: Spacing;
  $margin?: Spacing;
  $hasDisplayBlockOnMedium?: boolean;
}

const StyledSection = styled.section<StyledSectionProps>(
  ({
    $backgroundImage,
    $backgroundSmallImage,
    $backgroundColor,
    $spacingTop,
    $spacingBottom,
    $margin,
    $hasDisplayBlockOnMedium,
  }) => {
    return css`
      display: flex;
      align-items: center;
      justify-content: center;

      ${$backgroundColor &&
      css`
        background-color: ${theme.colors[$backgroundColor]};
      `}

      ${$backgroundImage &&
      css`
        @media screen and (min-width: ${theme.breakpoints.medium}) {
          background-image: url(${$backgroundImage});
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      `}

      ${$backgroundSmallImage &&
      css`
        @media screen and (max-width: ${theme.breakpoints.medium}) {
          background-image: url(${$backgroundSmallImage});
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      `}
      
      ${$hasDisplayBlockOnMedium &&
      css`
        @media screen and (max-width: ${theme.breakpoints.medium}) {
          display: block;
        }
      `}

      ${$spacingTop
        ? css`
            ${typeof $spacingTop === "object" &&
            css`
              padding-top: ${$spacingTop.small}px;
              @media screen and (min-width: ${theme.breakpoints.medium}) {
                padding-top: ${$spacingTop.medium}px;
              }
              @media screen and (min-width: ${theme.breakpoints.large}) {
                padding-top: ${$spacingTop.large}px;
              }
            `}

            ${typeof $spacingTop === "number" &&
            css`
              padding-top: ${$spacingTop}px;
            `}
          `
        : null}

      ${$spacingBottom
        ? css`
            ${typeof $spacingBottom === "object" &&
            css`
              padding-bottom: ${$spacingBottom.small}px;
              @media screen and (min-width: ${theme.breakpoints.medium}) {
                padding-bottom: ${$spacingBottom.medium}px;
              }
              @media screen and (min-width: ${theme.breakpoints.large}) {
                padding-bottom: ${$spacingBottom.large}px;
              }
            `}

            ${typeof $spacingBottom === "number" &&
            css`
              padding-bottom: ${$spacingBottom}px;
            `}
          `
        : null}

        ${$margin
        ? css`
            ${typeof $margin === "object" &&
            css`
              margin: ${$margin.small}px 0;
              @media screen and (min-width: ${theme.breakpoints.medium}) {
                margin: ${$margin.medium}px 0;
              }
              @media screen and (min-width: ${theme.breakpoints.large}) {
                margin: ${$margin.large}px 0;
              }
            `}

            ${typeof $margin === "number" &&
            css`
              margin: ${$margin}px 0;
            `}
          `
        : null}
    `;
  }
);

function SectionOrg({
  children,
  backgroundImage,
  backgroundSmallImage,
  backgroundColor,
  spacingTop,
  spacingBottom,
  htmlTag,
  margin,
  hasDisplayBlockOnMedium,
}: Props): ReactElement<Props> {
  return (
    <StyledSection
      $backgroundColor={backgroundColor}
      $backgroundImage={backgroundImage}
      $backgroundSmallImage={backgroundSmallImage}
      as={htmlTag}
      $margin={margin}
      $spacingTop={spacingTop}
      $spacingBottom={spacingBottom}
      $hasDisplayBlockOnMedium={hasDisplayBlockOnMedium}
    >
      {children}
    </StyledSection>
  );
}

export default SectionOrg;

import { ReactElement } from "react";
import styled from "styled-components";
import theme from "../../Global/theme";

const HamburgerWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    display: none;
  }
`;

const BurgerbarCommonStyles = `
  width: 2rem;
  height: 0.25rem;
  border-radius: 10px;
  background-color: ${theme.colors.primary};
  transform-origin: 1px;
  transition: all 0.3s ease-in-out 0s;
`;

interface BurgerBarProps {
  $isHamburgerOpen: boolean;
}

const FirstBurgerBar = styled.div<BurgerBarProps>`
  ${BurgerbarCommonStyles}

  transform: ${(props) =>
    props.$isHamburgerOpen ? "rotate(45deg)" : "rotate(0)"};
`;

const SecondBurgerBar = styled.div<BurgerBarProps>`
  ${BurgerbarCommonStyles}

  transform: ${(props) =>
    props.$isHamburgerOpen ? "translateX(-100%)" : "translateX(0)"};
  opacity: ${(props) => (props.$isHamburgerOpen ? 0 : 1)};
`;

const ThirdBurgerBar = styled.div<BurgerBarProps>`
  ${BurgerbarCommonStyles}

  transform: ${(props) =>
    props.$isHamburgerOpen ? "rotate(-45deg)" : "rotate(0)"};
`;

export interface Props {
  isHamburgerOpen: boolean;
  onClick: () => void;
}

function Hamburger({ isHamburgerOpen, onClick }: Props): ReactElement<Props> {
  return (
    <HamburgerWrapper onClick={onClick}>
      <FirstBurgerBar $isHamburgerOpen={isHamburgerOpen} />
      <SecondBurgerBar $isHamburgerOpen={isHamburgerOpen} />
      <ThirdBurgerBar $isHamburgerOpen={isHamburgerOpen} />
    </HamburgerWrapper>
  );
}

export default Hamburger;

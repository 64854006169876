import { ReactElement, ReactNode } from "react";
import styled, { css } from "styled-components";
import theme, { Colors, Typography } from "../Global/theme";

type HTMLTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "div" | "span";
type TextAlign = "center" | "left" | "right";

interface StyledTextAtmProps {
  $color: Colors;
  $typography: Typography;
  $textAlign?: TextAlign;
}

const StyledTextAtm = styled.p<StyledTextAtmProps>(
  ({ $color, $typography, $textAlign }) => {
    return css`
      color: ${theme.colors[$color]};

      font-family: ${theme.typography[$typography].fontFamily};
      font-weight: ${theme.typography[$typography].fontWeight};
      font-size: ${theme.typography[$typography].small.fontSize};
      line-height: ${theme.typography[$typography].small.lineHeight};

      @media screen and (min-width: ${theme.breakpoints.small}) {
        font-size: ${theme.typography[$typography].large.fontSize};
        line-height: ${theme.typography[$typography].large.lineHeight};
      }

      ${$textAlign &&
      css`
        text-align: ${$textAlign};
      `}
    `;
  }
);

export interface TextAtmProps {
  color: Colors;
  typography: Typography;
  children: ReactNode | ReactNode[];
  htmlTag?: HTMLTag;
  textAlign?: TextAlign;
}

function TextAtm({
  color,
  typography,
  children,
  htmlTag,
  textAlign,
}: TextAtmProps): ReactElement<TextAtmProps> {
  return (
    <StyledTextAtm
      as={htmlTag}
      $color={color}
      $typography={typography}
      $textAlign={textAlign}
    >
      {children}
    </StyledTextAtm>
  );
}

export default TextAtm;
